<template>
  <b-card v-if="statistics">
    <b-card-text>
      <p class="text-uppercase custom-text-disabled">{{ title }}</p>
      <b-list-group flush>
        <b-list-group-item
        ><div class="text-base">
          Total Task: <span class="text-body-2 font-weight-bold">
          <b-badge variant="light-primary">{{ statistics?.data?.total_task_count }}</b-badge>
          </span>
        </div></b-list-group-item
        >
        <b-list-group-item
        ><div class="text-base font-weight-semibold">
          Total Word Count:
          <span class="text-body-2 font-weight-bold">
            <b-badge variant="light-info">{{ statistics?.data?.total_word_count }}
          </b-badge></span>
        </div></b-list-group-item
        >

        <b-list-group-item
        ><div class="text-base font-weight-semibold">
          Total Complete Task:
          <span class="text-body-2 font-weight-bold">
            <b-badge variant="light-success">{{ statistics?.data?.total_complete_count }}
          </b-badge></span>
        </div></b-list-group-item
        >

        <b-list-group-item
        ><div class="text-base font-weight-semibold">
          Total Incomplete Task: <span class="text-body-2 font-weight-bold">
          <b-badge variant="light-warning">{{ statistics?.data?.total_incomplete_count }}
          </b-badge>
        </span>
        </div></b-list-group-item
        >

        <b-list-group-item
        ><div class="text-base font-weight-semibold">
          Pending Due Task: <span class="text-body-2 font-weight-bold">
          <b-badge variant="light-danger">{{ statistics?.data?.total_pending_overdue_count }}
          </b-badge>
        </span>
        </div></b-list-group-item
        >
      </b-list-group>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BDropdown,
  BDropdownItem,
  BBadge, BListGroupItem, BListGroup,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  components: {
    BListGroup,
    BListGroupItem,
    BBadge,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    VueApexCharts,
  },
  data() {
    return {
    }
  },
  created() {
  },

  props: {
    statistics: {},
    title: "",
  }
}
</script>
