<template>
  <div>
    <b-row>
      <b-col
        sm="12"
        lg="6"
      >
        <b-card-actions
          title="Filters"
          action-collapse
        >
          <div>
            <b-row>
              <b-col
                md="4"
                lg="3"
                xs="12"
              >
                <b-form-group label-for="searchTerm">
                  <b-form-input
                    v-model.lazy="searchTerm"
                    placeholder="Search By Task Name ..."
                    type="text"
                    class="mr-sm-1 mb-1 mb-sm-0 custom-font"
                    @keyup="onSearch"
                    @input="taskFiltering"
                  />
                </b-form-group>
              </b-col>

              <b-col
                v-if="filterDepertmentId"
                md="4"
                lg="3"
                xs="12"
              >
                <b-form-group>
                  <v-select
                    v-model="filterMemberId"
                    :options="userIdOption"
                    :reduce="(member) => member.id"
                    label="name"
                    placeholder=" By Department Member"
                    class="mr-sm-1 mb-1 mb-sm-0 custom-font"
                    @input="loadSelectedMember"
                  >
                    <template #option="data">
                      <UserSelect :user="data" />
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col
                v-else
                md="4"
                lg="3"
                xs="12"
              >
                <b-form-group>
                  <v-select
                    v-model="filterMemberId"
                    :options="filterAllUserIdOption"
                    :reduce="(member) => member.id"
                    label="name"
                    placeholder=" By All Member"
                    class="mr-sm-1 mb-1 mb-sm-0 custom-font"
                    @input="loadSelectedMember"
                  >
                    <template #option="data">
                      <UserSelect :user="data" />
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col
                md="4"
                lg="3"
                xs="12"
              >
                <b-form-group>
                  <v-select
                    v-model="filterDepertmentId"
                    :options="filterDepertmentIdOption"
                    :reduce="(depertment) => depertment.id"
                    label="name"
                    placeholder=" By Department"
                    class="mr-sm-1 mb-1 mb-sm-0 custom-font"
                    @input="setDepartmentWiseInfo"
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="4"
                lg="3"
                xs="12"
              >
                <v-select
                  v-model="filterSupervisorId"
                  :options="filterAllUserIdOption"
                  :reduce="(member) => member.id"
                  label="name"
                  placeholder=" By Supervisor"
                  class="mr-sm-1 mb-1 mb-sm-0 custom-font"
                  @input="loadSelectedMember"
                >
                  <template #option="data">
                    <UserSelect :user="data" />
                  </template>
                </v-select>
              </b-col>

              <b-col
                md="4"
                lg="3"
                xs="12"
              >
                <b-form-group>
                  <v-select
                    v-model="filterMilestoneBoardId"
                    :options="filterMilestoneBoardIdOption"
                    :reduce="(board) => board.id"
                    label="name"
                    placeholder="By Milestone Board"
                    class="mr-sm-1 mb-1 mb-sm-0 custom-font"
                    @input="setMilestoneBoardWiseInfo"
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="4"
                lg="3"
                xs="12"
              >
                <b-form-group>
                  <v-select
                    v-model="filterPhaseId"
                    :options="filterPhaseIdOption"
                    :reduce="(subtask) => subtask.id"
                    label="name"
                    placeholder=" By Phase"
                    class="mr-sm-1 mb-1 mb-sm-0 custom-font"
                    @input="setPhaseWiseInfo"
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="4"
                lg="3"
                xs="12"
              >
                <b-form-group>
                  <v-select
                    v-model="filterTaskId"
                    :options="filterTaskIdOption"
                    :reduce="(task) => task.id"
                    label="name"
                    placeholder=" By Task"
                    class="mr-sm-1 mb-1 mb-sm-0 custom-font"
                    @input="loadSelectedTask"
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="4"
                lg="3"
                xs="12"
              >
                <b-form-group>
                  <v-select
                    v-model="filterMilestoneOrTaskStatusType"
                    :options="filterMilestoneOrTaskStatusTypeOptionConstant"
                    :reduce="(item) => item.value"
                    label="name"
                    placeholder="Select Status Type"
                    class="mr-sm-1 mb-1 mb-sm-0 custom-font"
                    @input="loadSelectedMilestoneOrTask"
                  />
                </b-form-group>
              </b-col>

              <b-col
                v-if="filterMilestoneOrTaskStatusType == 'milestone_status'"
                md="4"
                lg="3"
                xs="12"
              >
                <b-form-group>
                  <v-select
                    v-model="filterMilestoneStatus"
                    :options="milestoneTaskBoardStatusConstants"
                    :reduce="(item) => item.value"
                    label="name"
                    placeholder="Select Milestone Status"
                    class="mr-sm-1 mb-1 mb-sm-0 custom-font"
                    @input="loadSelectedMilestoneStatus"
                  />
                </b-form-group>
              </b-col>

              <b-col
                v-else
                md="4"
                lg="3"
                xs="12"
              >
                <b-form-group>
                  <v-select
                    v-model="filterTaskStatus"
                    :options="filterTaskStatusOption"
                    :reduce="(item) => item.value"
                    label="name"
                    placeholder="Select Task Status"
                    class="mr-sm-1 mb-1 mb-sm-0 custom-font"
                    @input="loadSelectedTaskStatus"
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="4"
                lg="3"
                xs="12"
              >
                <b-form-group>
                  <v-select
                    v-model="filterDateType"
                    :options="taskDateTypeConstant"
                    :reduce="(item) => item.value"
                    label="name"
                    placeholder="Select Date Type"
                    class="mr-sm-1 mb-1 mb-sm-0 custom-font"
                    @input="loadSelectedDateType"
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="4"
                lg="3"
                xs="12"
              >
                <b-form-group>
                  <flat-pickr
                    v-model="filterRangeStartDate"
                    class="form-control"
                    placeholder="Filter Start Date Range"
                    :config="{ mode: 'range'}"
                    @onClose="loadItems"
                    @input="loadItems"
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="4"
                lg="3"
                xs="12"
              >
                <b-form-group>
                  <flat-pickr
                    v-model="filterRangeEndDate"
                    class="form-control"
                    placeholder="Filter End Date Range"
                    :config="{ mode: 'range'}"
                    @input="loadItems"
                  />
                </b-form-group>
              </b-col>

            </b-row>

            <b-row class="second-layer-margin">

              <template>
                <b-form-checkbox
                  :checked="switchValue"
                  class="custom-control-primary switch-button-margin"
                  name="check-button"
                  switch
                  @input="onSwitchChange"
                />
              </template>

              <b-col
                v-if="switchValue"
                md="3"
                lg="3"
                xs="12"
              >
                <v-select
                  v-model="filterProjectId"
                  :options="filterProjectIdOption"
                  :reduce="(task) => task.id"
                  label="name"
                  placeholder="By Project Name"
                  class="mr-sm-1 mb-1 mb-sm-0 custom-font"
                  @input="loadSelectedTask"
                />
              </b-col>

              <b-col
                v-else
                md="3"
                lg="3"
                xs="12"
              >
                <v-select
                  v-model="filterProjectLessBoardId"
                  :options="filterProjectLessBoardIdOption"
                  :reduce="(task) => task.id"
                  label="name"
                  placeholder="By Project Less Board Name"
                  class="mr-sm-1 mb-1 mb-sm-0 custom-font"
                  @input="loadSelectedTask"
                />
              </b-col>

            </b-row>

          </div>
        </b-card-actions>
      </b-col>
      <b-col
        sm="12"
        lg="3"
      >
        <TaskStatistics :title="'Task Statistics'" :statistics="taskStatistics" />
      </b-col>

      <b-col
        sm="12"
        lg="3"
      >
        <TaskStatistics :title="'Sub Task Statistics'" :statistics="subTaskStatistics" />
      </b-col>
    </b-row>

    <b-card-actions
      title="Subtask"
      action-collapse
    >
      <!-- table -->
      <vue-good-table
        mode="remote"
        style-class="vgt-table table-custom-style striped"
        :total-rows="subTaskTotalRecords"
        :is-loading.sync="isLoading"
        :rows="subTaskrows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'created_at', type: 'desc' }],
        }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- title -->
          <template v-if="props?.column?.field === 'format_tasks'">
            <span>
              <b-link
                @click="
                  onShowSubTaskDetailsPage(props.row.id)
                "
              >
                <div
                  class="font-weight-bold"
                  style="color:black"
                  v-html="breakTitle(props?.row?.title)"
                />

              </b-link>

              <div>
                Task:
                <b-link
                  @click="
                    onShowTaskDetailsPage(props.row.task?.data?.id, props.row.task?.data?.short_title)
                  "
                >
                  <div
                    class="font-weight-bold"
                    style="color:black"
                    v-html="breakTitle(props?.row?.task?.data?.title)"
                  />

                </b-link>
              </div>

              <b-badge
                v-if="props?.row?.is_completed"
                variant="light-success"
                class="badge-glow"
              >
                Completed
              </b-badge>

              <b-badge
                v-if="props?.row?.overdue"
                variant="light-danger"
              >
                Overdue
              </b-badge>

              <div>
                Department:
                <template v-if="props?.row?.milestoneTaskBoard?.data?.department?.data?.name">
                  <b-badge
                    v-if="props?.row?.milestoneTaskBoard?.data?.department?.data?.name"
                    variant="light-info"
                    pill
                  >
                    {{ props?.row?.milestoneTaskBoard?.data?.department?.data?.name }}
                  </b-badge>
                </template>
                <b-badge
                  v-else
                  variant="light-danger"
                  pill
                >
                  N / A
                </b-badge>
              </div>

              Phase:
              <template v-if="props?.row?.phase?.data?.title">
                <b-badge variant="light-primary">
                  {{ props?.row?.phase?.data?.title }}
                </b-badge>
              </template>

              <b-badge
                v-else
                variant="light-danger"
                pill
              >
                N / A
              </b-badge>

              <div>
                Milestone Title: <b-link
                  v-if="props.row.milestoneTaskBoard?.data?.id"
                  @click="
                    onShowBoardDetailsPage(props.row.milestoneTaskBoard.data.id)
                  "
                >
                  <b>{{ props?.row?.milestoneTaskBoard?.data?.title }}</b>
                </b-link>
                <b-badge
                  v-else
                  variant="light-danger"
                  pill
                >
                  N / A
                </b-badge>
                <br>
                Project Status: <b-badge
                  v-if="props?.row?.milestoneTaskBoard?.data?.status"
                  :variant="
                    setStatusVariant(
                      props?.row?.milestoneTaskBoard?.data?.status
                    )
                  "
                  class="badge-glow"
                >
                  {{ setStatus(props?.row?.milestoneTaskBoard?.data?.status) }}
                </b-badge>
                <b-badge
                  v-else
                  variant="light-danger"
                  pill
                >
                  N / A
                </b-badge>
              </div>

              <div v-if="props?.row?.wordcount > 0">
                Word Count:
                <b-badge variant="light-info">
                  {{ parseInt(props?.row?.wordcount) }}
                </b-badge>
              </div>
            </span>
          </template>

          <template v-if="props?.column?.field === 'format_member_info'">
            <span
              v-for="(member, index) in props.row.team.data"
              :key="index"
            >
              <template v-if="index <= 2">
                <b-avatar
                  :src="member?.avatar === '' ? '/avatar.svg' : member?.avatar"
                  size="26"
                  style="margin: 1px"
                /></template>
            </span>
            <span v-if="props?.row?.team_count > 3">
              + {{ props?.row?.team_count - 3 }} more
            </span>
            <b-button
              v-if="props?.row?.team_count > 0"
              variant="outline-primary"
              class="btn-icon rounded-circle"
              style="margin-left: 5px"
              @click="showAllAddedMemberModal(props?.row?.team?.data)"
            >
              <feather-icon icon="UsersIcon" />
            </b-button>
            <b-badge
              v-else
              variant="danger"
            >
              N / A
            </b-badge>
          </template>

          <template v-if="props?.column?.field === 'format_phase_info'">
            <b-badge variant="primary">
              {{ props?.row?.phase?.data?.title }}              </b-badge>
          </template>

          <template v-if="props?.column?.field === 'format_supervisor'">
            <span
              v-if="props?.row?.milestoneTaskBoard?.data?.supervisor?.data"
            >
              <UserCard :user="props?.row?.milestoneTaskBoard?.data?.supervisor?.data" />
            </span>
            <span v-else>
              <div class="text-nowrap">
                <span> <b>No Supervisor Assigned </b></span>
              </div>
            </span>
          </template>

          <template v-if="props?.column?.field === 'format_date'">
            <b>Assigned Start Date: </b>
            <span v-if="props?.row?.start_date">
              {{ props?.row?.start_date }}
            </span>
            <span v-else>
              <b-badge
                pill
                variant="light-primary"
              > N/A </b-badge>
            </span>
            <br>
            <b>Actual Start Date: </b>
            <span v-if="props?.row?.actual_start_date">
              {{ props?.row?.actual_start_date }}
            </span>
            <span v-else>
              <b-badge
                pill
                variant="light-primary"
              > N/A </b-badge>
            </span>
            <br>

            <b>Assigned End Date: </b>
            <span v-if="props?.row?.assigned_end_date">
              {{ props?.row?.assigned_end_date }}
            </span>
            <span v-else>
              <b-badge
                pill
                variant="light-primary"
              > N/A </b-badge>
            </span>
            <br>
            <b>Actual End Date: </b>
            <span v-if="props?.row?.actual_end_date">
              {{ props?.row?.actual_end_date }}
            </span>
            <span v-else>
              <b-badge
                pill
                variant="light-primary"
              > N/A </b-badge>
            </span>
          </template>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '20', '50']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
      <!-- table -->
    </b-card-actions>

    <b-card>
      <div>
        <!-- table -->
        <vue-good-table
          mode="remote"
          style-class="vgt-table table-custom-style striped"
          :total-rows="totalRecords"
          :is-loading.sync="isLoading"
          :rows="rows"
          :sort-options="{
            enabled: false,
            multipleColumns: true,
            initialSortBy: [{ field: 'created_at', type: 'desc' }],
          }"
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- title -->
            <template v-if="props?.column?.field === 'format_tasks'">
              <span>
                <b-link
                  @click="
                    onShowTaskDetailsPage(props.row.id, props.row.short_title)
                  "
                >
                  <div
                    class="font-weight-bold"
                    style="color:black"
                    v-html="breakTitle(props?.row?.title)"
                  />

                </b-link>
                <b-badge
                  v-if="props?.row?.is_completed"
                  variant="light-success"
                  class="badge-glow"
                >
                  Completed
                </b-badge>

                <b-badge
                  v-if="props?.row?.overdue"
                  variant="light-danger"
                >
                  Overdue
                </b-badge>

                <div>
                  Department:
                  <template v-if="props?.row?.milestoneTaskBoard?.data?.department?.data?.name">
                    <b-badge
                      v-if="props?.row?.milestoneTaskBoard?.data?.department?.data?.name"
                      variant="light-info"
                      pill
                    >
                      {{ props?.row?.milestoneTaskBoard?.data?.department?.data?.name }}
                    </b-badge>
                  </template>
                  <b-badge
                    v-else
                    variant="light-danger"
                    pill
                  >
                    N / A
                  </b-badge>
                </div>

                Phase:
                <template v-if="props?.row?.phase?.data?.title">
                  <b-badge variant="light-primary">
                    {{ props?.row?.phase?.data?.title }}
                  </b-badge>
                </template>

                <b-badge
                  v-else
                  variant="light-danger"
                  pill
                >
                  N / A
                </b-badge>

                <div>
                  Milestone Title: <b-link
                    v-if="props.row.milestoneTaskBoard?.data?.id"
                    @click="
                      onShowBoardDetailsPage(props.row.milestoneTaskBoard.data.id)
                    "
                  >
                    <b>{{ props?.row?.milestoneTaskBoard?.data?.title }}</b>
                  </b-link>
                  <b-badge
                    v-else
                    variant="light-danger"
                    pill
                  >
                    N / A
                  </b-badge>
                  <br>
                  Project Status: <b-badge
                    v-if="props?.row?.milestoneTaskBoard?.data?.status"
                    :variant="
                      setStatusVariant(
                      props?.row?.milestoneTaskBoard?.data?.status
                      )
                    "
                    class="badge-glow"
                  >
                    {{ setStatus(props?.row?.milestoneTaskBoard?.data?.status) }}
                  </b-badge>
                  <b-badge
                    v-else
                    variant="light-danger"
                    pill
                  >
                    N / A
                  </b-badge>
                </div>

                <div v-if="props?.row?.wordcount > 0">
                  Word Count:
                  <b-badge variant="light-info">
                    {{ parseInt(props?.row?.wordcount) }}
                  </b-badge>
                </div>
              </span>
            </template>

            <template v-if="props?.column?.field === 'format_member_info'">
              <span
                v-for="(member, index) in props.row.team.data"
                :key="index"
              >
                <template v-if="index <= 2">
                  <b-avatar
                    :src="member?.avatar === '' ? '/avatar.svg' : member?.avatar"
                    size="26"
                    style="margin: 1px"
                  /></template>
              </span>
              <span v-if="props?.row?.team_count > 3">
                + {{ props?.row?.team_count - 3 }} more
              </span>
              <b-button
                v-if="props?.row?.team_count > 0"
                variant="outline-primary"
                class="btn-icon rounded-circle"
                style="margin-left: 5px"
                @click="showAllAddedMemberModal(props?.row?.team?.data)"
              >
                <feather-icon icon="UsersIcon" />
              </b-button>
              <b-badge
                v-else
                variant="danger"
              >
                N / A
              </b-badge>
            </template>

            <template v-if="props?.column?.field === 'format_phase_info'">
              <b-badge variant="primary">
                {{ props?.row?.phase?.data?.title }}              </b-badge>
            </template>

            <template v-if="props?.column?.field === 'format_supervisor'">
              <span
                v-if="props?.row?.milestoneTaskBoard?.data?.supervisor?.data"
              >
                <UserCard :user="props?.row?.milestoneTaskBoard?.data?.supervisor?.data" />
              </span>
              <span v-else>
                <div class="text-nowrap">
                  <span> <b>No Supervisor Assigned </b></span>
                </div>
              </span>
            </template>

            <template v-if="props?.column?.field === 'format_date'">
              <b>Assigned Start Date: </b>
              <span v-if="props?.row?.start_date">
                {{ props?.row?.start_date }}
              </span>
              <span v-else>
                <b-badge
                  pill
                  variant="light-primary"
                > N/A </b-badge>
              </span>
              <br>
              <b>Actual Start Date: </b>
              <span v-if="props?.row?.actual_start_date">
                {{ props?.row?.actual_start_date }}
              </span>
              <span v-else>
                <b-badge
                  pill
                  variant="light-primary"
                > N/A </b-badge>
              </span>
              <br>

              <b>Assigned End Date: </b>
              <span v-if="props?.row?.assigned_end_date">
                {{ props?.row?.assigned_end_date }}
              </span>
              <span v-else>
                <b-badge
                  pill
                  variant="light-primary"
                > N/A </b-badge>
              </span>
              <br>
              <b>Actual End Date: </b>
              <span v-if="props?.row?.actual_end_date">
                {{ props?.row?.actual_end_date }}
              </span>
              <span v-else>
                <b-badge
                  pill
                  variant="light-primary"
                > N/A </b-badge>
              </span>
            </template>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10', '20', '50']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
        <!-- table -->
      </div>

      <b-modal
        id="modal-all-added-member-form"
        centered
        title="Assigned Members"
        hide-footer
        no-close-on-backdrop
        @hidden="hiddenAllAddedMemberModal"
      >
        <ul>
          <li
            v-for="(member, index) in assingedMember"
            :key="index"
          >
            <b-avatar
              :src="member?.avatar === '' ? '/avatar.svg' : member?.avatar"
              size="26"
              style="margin: 1px"
            />
            {{ member.name }}
          </li>
        </ul>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormTextarea,
  BFormDatepicker,
  BLink,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  VBTooltip,
  BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required as validate_required,
  max,
  size,
  ext,
  numeric as validate_numeric,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import FinancialEstimationForm from '@/views/admin/finance/project/FinancialEstimationForm.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import { projectTypeConstants } from '@/helpers/constant/projectTypeConstant'
import { paymentTypeConstants } from '@/helpers/constant/paymentTypeConstant'
import {
  LEAD_MANAGEMENT_ACCESS,
  LEAD_DETAILS_EXPORT,
  LEAD_DETAILS_CREATE,
  LEAD_DETAILS_EDIT,
  LEAD_DETAILS_DELETE,
} from '@/helpers/permissionsConstant'
import { milestoneTaskBoardStatusConstants } from '@/helpers/constant/milestoneTaskBoardStatusConstant.js'
import { taskDateTypeConstant } from '@/helpers/constant/taskDateTypeConstant.js'

import { filterMilestoneOrTaskStatusTypeOptionConstant } from '@/helpers/constant/filterMilestoneOrTaskStatusTypeOptionConstant.js'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import UserSelect from '@/layouts/components/UserSelect.vue'
import UserCard from '@/layouts/components/UserCard.vue'
import TaskStatistics from '@/layouts/components/task/TaskStatistics.vue'

export default {
  name: 'MilestoneBoardGlobalList',
  components: {
    TaskStatistics,
    UserCard,
    UserSelect,
    BCardActions,
    BLink,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BRow,
    BCol,
    BFormTextarea,
    BFormDatepicker,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    flatPickr,
    BMedia,
    BMediaAside,
    BMediaBody,
    FinancialEstimationForm,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      LEAD_MANAGEMENT_ACCESS,
      LEAD_DETAILS_EXPORT,
      LEAD_DETAILS_CREATE,
      LEAD_DETAILS_EDIT,
      LEAD_DETAILS_DELETE,
      // Custom Validation
      amountNotMatch: false,
      amountNotMatchError: '',
      vatNotMatch: false,
      vatNotMatchError: '',
      taxNotMatch: false,
      taxNotMatchError: '',
      estimationSubmitDisabled: false,
      // Financial Estimation
      showFinancialEstimationComponent: false,
      selectedLeadInfo: {},
      projectTypeConstants,
      paymentTypeConstants,
      projectType: '',
      paymentType: '',
      amount: '',
      timeDuration: '',
      vatAmount: '',
      taxAmount: '',
      grandAmount: '',
      milestones: '',
      milestoneAmmount: [],
      milestoneVat: [],
      milestoneTax: [],
      milestoneTotal: [],

      filterRangeStartDate: null,
      filterRangeEndDate: null,

      milestoneDeadLine: [],
      milestonePaymentType: [],
      isMileStoneLoading: false,
      isFinancialEstimationFormLoading: false,

      // Table
      milestoneTaskBoardStatusConstants,
      taskDateTypeConstant,
      leadId: '',
      modelType: '',
      lead_category_id: '',
      categoryIdOption: [],
      lead_source_id: '',
      sourceIdOption: [],
      department_id: '',
      dial_code_country_id: '',
      dialCodeCountryIdOption: [],
      lead_status_id: '',
      statusIdOption: [],
      user_id: '',
      userIdOption: [],
      allUserIdOption: [],
      title: '',
      keyContactPerson: '',
      company_name: '',
      country_id: '',
      countryIdOption: [],
      email: '',
      mobile: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      street: '',
      zip_code: '',
      note: '',
      website_link: '',
      leadStatusInfo: [],

      // Assigned Member
      assingedMember: [],

      taskStatistics: [],
      subTaskStatistics: [],

      // filter options
      filterSourceId: '',
      filterSourceIdOption: [],
      filterStatusId: '',
      filterStatusIdOption: [],
      filterCategoryId: '',
      filterCategoryIdOption: [],
      filterEmpId: '',
      filterEmpIdOption: [],
      filterMilestoneBoardId: '',
      filterPhaseId: '',
      filterTaskId: '',
      filterMilestoneOrTaskStatusType: 'task_status',
      filterMilestoneStatus: '',
      filterTaskStatus: '',
      filterDateType: 'assigned',
      filterSubTaskId: '',
      filterDepertmentId: '',
      filterMemberId: '',
      filterSupervisorId: '',
      filterMilestoneBoardIdOption: [],
      filterDepertmentIdOption: [],
      filterAllUserIdOption: [],
      filterMemberIdOption: [],
      filterTaskIdOption: [],
      filterPhaseIdOption: [],
      filterSubTaskIdOption: [],
      filterCountryId: '',
      filterCountryIdOption: [],

      filterStartDateFrom: '',
      filterStartDateTo: '',

      filterEndDateFrom: '',
      filterEndDateTo: '',

      filterMilestoneOrTaskStatusTypeOptionConstant,
      filterMilestoneStatusOption: [],
      filterProjectIdOption: [],
      filterProjectId: '',
      filterProjectLessBoardIdOption: [],
      filterProjectLessBoardId: '',
      switchValue: false,

      filterTaskStatusOption: [
        {
          name: 'Not Completed',
          value: false,
        },
        {
          value: true,
          name: 'Completed',
        },
        {
          value: 'overdue',
          name: 'Overdue',
        },
      ],
      params: [],
      pageLength: 10,
      columns: [
        {
          label: 'Task',
          field: 'format_tasks',
          sortable: false,
        },
        {
          label: 'Member',
          field: 'format_member_info',
          sortable: false,
        },
        {
          label: 'Supervisor',
          field: 'format_supervisor',
          sortable: false,
        },
        {
          label: 'Date',
          field: 'format_date',
          sortable: false,
        },
      ],
      rows: [],
      subTaskrows: [],
      searchTerm: '',
      sourceId: '',
      statusId: '',
      categoryId: '',
      empId: '',
      departmentId: '',
      delayTimer: null,
      isLoading: false,
      isLeadsFormSubmitLoading: false,
      subTaskTotalRecords: 0,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: 'id',
            type: 'desc',
          },
          // { field: "name", type: "desc" },
          {
            field: 'created_at',
            type: 'desc',
          },
        ],
        page: 1,
        perPage: 10,
      },
    }
  },

  computed: {
    ...mapGetters({
      permissions: 'userModule/getPermissions',
    }),
  },

  async created() {
    await this.loadItems()

    try {
      const [depertments, members, allMembers] = await Promise.all([
        this.getDepertments(),
        this.getDepartmentWiseAssignEmployee(),
        this.getAllUsers(),
      ])

      // filter depertment
      this.filterDepertmentIdOption = (depertments?.data?.data || []).map(
        item => {
          const { name } = item
          return {
            name,
            id: item.id,
          }
        },
      )

      // filter member
      this.filterAllUserIdOption = (allMembers?.data?.data || []).map(
        item => ({
          avatar: item?.avatar,
          name: item?.name,
          email: item?.email,
          mobile: item?.mobile,
          employee_number: item?.employee_number,
          id: item?.id,
          departmentId: item?.department_id,
        }),
      )
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'BellIcon',
          variant: 'danger',
          text: error?.response?.data?.message,
        },
      })
    }
  },

  methods: {
    // isOverdue(assignedEndDateString, actualEndDateString) {
    //   if (assignedEndDateString != null) {
    //     const assignedEndDate = new Date(assignedEndDateString);
    //     const actualEndDate = actualEndDateString
    //       ? new Date(actualEndDateString)
    //       : undefined;
    //     const today = new Date();

    //     if (actualEndDate === undefined) {
    //       return assignedEndDate < today;
    //     } else {
    //       return actualEndDate > assignedEndDate;
    //     }
    //   }

    //   return false;
    // },

    //todo: refactor
    handleDateRangeStartChange() {

      this.filterStartDateFrom = ''
      this.filterStartDateTo = ''

      if (this.filterRangeStartDate && this.filterRangeStartDate !== '') {
        const dates = this.filterRangeStartDate.split(' to ')

        if (dates.length === 1) {
          // Case where only one date is provided
          this.filterStartDateFrom = dates[0]
          this.filterStartDateTo = dates[0]

        } else if (dates.length === 2) {

          // Case where both start and end dates are provided
          this.filterStartDateFrom = dates[0]
          this.filterStartDateTo = dates[1]
        } else {
          // Handle invalid date range format
          console.error('Invalid date range format')
          // You can choose to handle this case differently based on your requirements
        }
      }
    },
    handleDateRangeEndChange() {

      this.filterEndDateFrom = ''
      this.filterEndDateTo = ''

      if (this.filterRangeEndDate && this.filterRangeEndDate !== '') {
        const dates = this.filterRangeEndDate.split(' to ')

        if (dates.length === 1) {
          // Case where only one date is provided
          this.filterEndDateFrom = dates[0]
          this.filterEndDateTo = dates[0]
        } else if (dates.length === 2) {
          // Case where both start and end dates are provided
          this.filterEndDateFrom = dates[0]
          this.filterEndDateTo = dates[1]
        } else {
          // Handle invalid date range format
          console.error('Invalid date range format')
          // You can choose to handle this case differently based on your requirements
        }
      }
    },

    onSwitchChange() {
      this.switchValue = !this.switchValue

      this.filterProjectId = ''
      this.filterProjectLessBoardId = ''

      this.loadItems()
    },

    // break title
    breakTitle(title) {
      const maxLength = 80
      if (title && title.length > maxLength) {
        const regex = new RegExp(`(.{1,${maxLength}})`, 'g')
        const truncatedTitle = title.replace(regex, '$1<br>')
        return truncatedTitle
      }
      return title
    },

    setStatus(status) {
      const statusType = (this.milestoneTaskBoardStatusConstants || []).find(
        item => item?.value == status,
      )

      return statusType?.name
    },

    setStatusVariant(status) {
      const statusType = (this.milestoneTaskBoardStatusConstants || []).find(
        item => item?.value == status,
      )

      return statusType?.badgeVariant
    },

    filterTasksByDate() {
      this.loadItems()
    },

    async setDepartmentWiseInfo(deptId) {
      this.loadItems()

      this.user_id = ''
      this.filterMemberId = ''
      this.filterMilestoneBoardId = ''
      const [departmentEmployee, departmentMilestones] = await Promise.all([
        this.getDepartmentWiseAssignEmployee(deptId),
        this.getDepartmentWiseMilestones(deptId),
      ])

      // filter users
      this.userIdOption = (departmentEmployee?.data?.data || []).map(item => {
        const { name } = item
        return {
          name,
          id: item.id,
        }
      })

      // filter milestone board
      this.setMilestoneBoardInfo(deptId, departmentMilestones)
    },

    async setMilestoneBoardInfo(deptId, departmentMilestones) {
      if (deptId) {
        try {
          this.filterMilestoneBoardIdOption = (
              departmentMilestones?.data?.data?.milestoneBoards?.data || []
          ).map(item => {
            const name = item.title
            return {
              name,
              id: item.id,
            }
          })
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              variant: 'danger',
              text: error?.response?.data?.message,
            },
          })
        }
      } else {
        try {
          const [allMilestoneBoard] = await Promise.all([
            this.$api.get('api/milestone-task-boards/all/boards'),
          ])

          this.filterMilestoneBoardIdOption = (
              allMilestoneBoard?.data?.data || []
          ).map(item => {
            const name = item.title
            return {
              name,
              id: item.id,
            }
          })
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              variant: 'danger',
              text: error?.response?.data?.message,
            },
          })
        }
      }
    },

    async setMilestoneBoardWiseInfo(milestoneBoardId) {
      this.loadItems()

      this.filterPhaseId = ''
      const [milestonePhase] = await Promise.all([
        this.getMilestoneWisePhase(milestoneBoardId),
      ])

      // filter phase
      this.filterPhaseIdOption = (milestonePhase?.data?.data || []).map(
        item => {
          const name = item.title
          return {
            name,
            id: item.id,
          }
        },
      )
    },

    async setPhaseWiseInfo(phaseId) {
      this.loadItems()
      this.filterTaskId = ''
      const [phaseTask] = await Promise.all([this.getPhaseWiseTask(phaseId)])

      // filter tasks
      this.filterTaskIdOption = (phaseTask?.data?.data || []).map(item => {
        const name = item.title
        return {
          name,
          id: item.id,
        }
      })
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },
    onShowBoardDetailsPage(id) {
      this.$router.push({
        name: 'admin-task-board-details',
        params: { id },
      })
    },
    onShowTaskDetailsPage(id, task) {
      this.$router.push({
        name: 'admin-task-board-task-details',
        params: {
          id,
          task,
        },
      })
    },

    onShowSubTaskDetailsPage(id) {
      this.$router.push({
        name: 'admin-task-board-task-subtask-details',
        params: { id },
      })
    },

    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },
    formatFnTableLastContactDate(value) {
      if (value) {
        return this.$moment(value)
          .format('MMM Do YYYY')
      }
      return 'Not Set'
    },
    showAllAddedMemberModal(value) {
      this.assingedMember = value.map(item => {
        const name = `${item?.name} (${item?.email})`
        return {
          name,
          id: item.id,
          avatar: item?.avatar,
        }
      })

      this.$bvModal.show('modal-all-added-member-form')
    },
    hiddenAllAddedMemberModal() {
      this.$bvModal.hide('modal-all-added-member-form')
    },
    async getGlobalData(params) {
      return await this.$api.get(
        'api/milestone-task-boards/tasks/all?include=phase,milestoneTaskBoard.department,milestoneTaskBoard.supervisor,team',
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
            filterMilestoneBoardId: params.filterMilestoneBoardId,
            filterDepertmentId: params.filterDepertmentId,
            filterSupervisorId: params.filterSupervisorId,
            filterMemberId: params.filterMemberId,
            filterPhaseId: params.filterPhaseId,
            filterTaskId: params.filterTaskId,
            filterTaskStatus: params.filterTaskStatus,
            filterMilestoneStatus: params.filterMilestoneStatus,
            filterDateType: params.filterDateType,
            filterStartDateFrom: this.filterStartDateFrom,
            filterStartDateTo: this.filterStartDateTo,
            filterEndDateFrom: this.filterEndDateFrom,
            filterEndDateTo: this.filterEndDateTo,
            filterProjectId: params.filterProjectId,
            filterProjectLessBoardId: params.filterProjectLessBoardId,
          },
        },
      )
    },
    async getSubTaskData(params) {
      return await this.$api.get(
        'api/milestone-task-boards/sub-tasks/all?include=phase,milestoneTaskBoard.department,milestoneTaskBoard.supervisor,team,task',
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
            filterMilestoneBoardId: params.filterMilestoneBoardId,
            filterDepertmentId: params.filterDepertmentId,
            filterSupervisorId: params.filterSupervisorId,
            filterMemberId: params.filterMemberId,
            filterPhaseId: params.filterPhaseId,
            filterTaskId: params.filterTaskId,
            filterTaskStatus: params.filterTaskStatus,
            filterMilestoneStatus: params.filterMilestoneStatus,
            filterDateType: params.filterDateType,
            filterStartDateFrom: this.filterStartDateFrom,
            filterStartDateTo: this.filterStartDateTo,
            filterEndDateFrom: this.filterEndDateFrom,
            filterEndDateTo: this.filterEndDateTo,
            filterProjectId: params.filterProjectId,
            filterProjectLessBoardId: params.filterProjectLessBoardId,
          },
        },
      )
    },

    async fetchTaskStatistics(params) {
      return await this.$api.get(
        'api/milestone-task-boards/tasks/statistics',
        {
          params: {
            q: params.q,
            filterMilestoneBoardId: params.filterMilestoneBoardId,
            filterDepertmentId: params.filterDepertmentId,
            filterSupervisorId: params.filterSupervisorId,
            filterMemberId: params.filterMemberId,
            filterPhaseId: params.filterPhaseId,
            filterTaskId: params.filterTaskId,
            filterTaskStatus: params.filterTaskStatus,
            filterMilestoneStatus: params.filterMilestoneStatus,
            filterDateType: params.filterDateType,
            filterStartDateFrom: this.filterStartDateFrom,
            filterStartDateTo: this.filterStartDateTo,
            filterEndDateFrom: this.filterEndDateFrom,
            filterEndDateTo: this.filterEndDateTo,
            filterProjectId: params.filterProjectId,
            filterProjectLessBoardId: params.filterProjectLessBoardId,
          },
        },
      )
    },
    async fetchSubTaskStatistics(params) {
      return await this.$api.get(
        'api/milestone-task-boards/sub-tasks/statistics',
        {
          params: {
            q: params.q,
            filterMilestoneBoardId: params.filterMilestoneBoardId,
            filterDepertmentId: params.filterDepertmentId,
            filterSupervisorId: params.filterSupervisorId,
            filterMemberId: params.filterMemberId,
            filterPhaseId: params.filterPhaseId,
            filterTaskId: params.filterTaskId,
            filterTaskStatus: params.filterTaskStatus,
            filterMilestoneStatus: params.filterMilestoneStatus,
            filterDateType: params.filterDateType,
            filterStartDateFrom: this.filterStartDateFrom,
            filterStartDateTo: this.filterStartDateTo,
            filterEndDateFrom: this.filterEndDateFrom,
            filterEndDateTo: this.filterEndDateTo,
            filterProjectId: params.filterProjectId,
            filterProjectLessBoardId: params.filterProjectLessBoardId,
          },
        },
      )
    },

    async getDepertments() {
      return await this.$api.get('api/departments')
    },

    async getDepartmentWiseAssignEmployee(deptId) {
      if (deptId) {
        return await this.$api.get(`api/department/${deptId}/users`)
      }
    },

    async getDepartmentWiseMilestones(deptId) {
      if (deptId) {
        return await this.$api.get(
          `api/departments/${deptId}?include=milestoneBoards`,
        )
      }
    },

    async getAllUsers() {
      return await this.$api.get('api/users/all')
    },

    async getMilestoneWisePhase(milestoneBoardId) {
      if (milestoneBoardId) {
        return await this.$api.get(
          `api/milestone-task-boards/${milestoneBoardId}/phases/all`,
        )
      }
    },

    async getPhaseWiseTask(phaseId) {
      if (phaseId) {
        return await this.$api.get(`api/phases/${phaseId}/tasks`)
      }
    },

    async getSubTasks() {
      return await this.$api.get('api/milestone-task-boards?include=subTasks')
    },

    async getApprovedProjects() {
      const [allApprovedProjects] = await Promise.all([
        this.$api.get('api/projects/approve'),
      ])

      this.filterProjectIdOption = (
          allApprovedProjects?.data?.data || []
      ).map(item => {
        const name = item.lead.title
        return {
          name,
          id: item.id,
        }
      })
    },

    async getProjectLessBoardOptions() {
      const [allProjectLessBoard] = await Promise.all([
        this.$api.get('api/milestone-task-boards/all/project-less-boards'),
      ])

      this.filterProjectLessBoardIdOption = (
          allProjectLessBoard?.data?.data || []
      ).map(item => {
        const name = item.title
        return {
          name,
          id: item.id,
        }
      })
    },

    // loadSelectedMilestoneBoard() {
    //   try {
    //     this.loadItems();
    //   } catch (error) {
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: "Error",
    //         icon: "BellIcon",
    //         variant: "danger",
    //         text: error?.response?.data?.message,
    //       },
    //     });
    //   }
    // },

    loadSelectedDepertment() {
      try {
        this.loadItems()
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },

    loadSelectedMember() {
      try {
        this.loadItems()
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },

    loadSelectedTask() {
      try {
        this.loadItems()
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },

    loadSelectedMilestoneOrTask() {
      try {
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },

    loadSelectedTaskStatus() {
      try {
        this.loadItems()
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },

    loadSelectedDateType() {
      try {
        this.loadItems()
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },

    loadSelectedMilestoneStatus() {
      try {
        this.loadItems()
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },
    async loadItems() {
      try {

        this.handleDateRangeStartChange()
        this.handleDateRangeEndChange()

        const [globaldata, globalSubTaskData] = await Promise.all([
          this.getGlobalData({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
            filterMilestoneBoardId: this.filterMilestoneBoardId,
            filterDepertmentId: this.filterDepertmentId,
            filterSupervisorId: this.filterSupervisorId,
            filterMemberId: this.filterMemberId,
            filterPhaseId: this.filterPhaseId,
            filterTaskId: this.filterTaskId,
            filterTaskStatus: this.filterTaskStatus,
            filterMilestoneStatus: this.filterMilestoneStatus,
            filterDateType: this.filterDateType,

            filterStartDateFrom: this.filterStartDateFrom,
            filterStartDateTo: this.filterStartDateTo,
            filterEndDateFrom: this.filterEndDateFrom,
            filterEndDateTo: this.filterEndDateTo,

            filterProjectId: this.filterProjectId,
            filterProjectLessBoardId: this.filterProjectLessBoardId,
          }),
          this.getSubTaskData({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
            filterMilestoneBoardId: this.filterMilestoneBoardId,
            filterDepertmentId: this.filterDepertmentId,
            filterSupervisorId: this.filterSupervisorId,
            filterMemberId: this.filterMemberId,
            filterPhaseId: this.filterPhaseId,
            filterTaskId: this.filterTaskId,
            filterTaskStatus: this.filterTaskStatus,
            filterMilestoneStatus: this.filterMilestoneStatus,
            filterDateType: this.filterDateType,
            filterStartDateFrom: this.filterStartDateFrom,
            filterStartDateTo: this.filterStartDateTo,
            filterEndDateFrom: this.filterEndDateFrom,
            filterEndDateTo: this.filterEndDateTo,
            filterProjectId: this.filterProjectId,
            filterProjectLessBoardId: this.filterProjectLessBoardId,
          }),
        ])

        const data = globaldata?.data?.data
        const meta = globaldata?.data?.meta
        this.totalRecords = meta?.pagination?.total
        this.rows = data

        const subTask = globalSubTaskData?.data?.data
        const subTaskMeta = globalSubTaskData?.data?.meta
        this.subTaskTotalRecords = subTaskMeta?.pagination?.total
        this.subTaskrows = subTask

        // call all milestone api, if department is selected then don't call milestone board api
        if (!this.filterDepertmentId) {
          this.setMilestoneBoardInfo()
        }

        // get project approved options
        this.getApprovedProjects()

        // get project less board options
        this.getProjectLessBoardOptions()

        const [taskStatistics, subTaskStatistics] = await Promise.all([
          this.fetchTaskStatistics({
            q: this.searchTerm,
            filterMilestoneBoardId: this.filterMilestoneBoardId,
            filterDepertmentId: this.filterDepertmentId,
            filterSupervisorId: this.filterSupervisorId,
            filterMemberId: this.filterMemberId,
            filterPhaseId: this.filterPhaseId,
            filterTaskId: this.filterTaskId,
            filterTaskStatus: this.filterTaskStatus,
            filterMilestoneStatus: this.filterMilestoneStatus,
            filterDateType: this.filterDateType,

            filterStartDateFrom: this.filterStartDateFrom,
            filterStartDateTo: this.filterStartDateTo,
            filterEndDateFrom: this.filterEndDateFrom,
            filterEndDateTo: this.filterEndDateTo,

            filterProjectId: this.filterProjectId,
            filterProjectLessBoardId: this.filterProjectLessBoardId,
          }),
          this.fetchSubTaskStatistics({
            q: this.searchTerm,
            filterMilestoneBoardId: this.filterMilestoneBoardId,
            filterDepertmentId: this.filterDepertmentId,
            filterSupervisorId: this.filterSupervisorId,
            filterMemberId: this.filterMemberId,
            filterPhaseId: this.filterPhaseId,
            filterTaskId: this.filterTaskId,
            filterTaskStatus: this.filterTaskStatus,
            filterMilestoneStatus: this.filterMilestoneStatus,
            filterDateType: this.filterDateType,

            filterStartDateFrom: this.filterStartDateFrom,
            filterStartDateTo: this.filterStartDateTo,
            filterEndDateFrom: this.filterEndDateFrom,
            filterEndDateTo: this.filterEndDateTo,

            filterProjectId: this.filterProjectId,
            filterProjectLessBoardId: this.filterProjectLessBoardId,
          }),
        ])

        this.taskStatistics = taskStatistics
        this.subTaskStatistics = subTaskStatistics
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },
    async taskFiltering() {
      this.loadItems()
    },
    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer)
        this.delayTimer = null
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems()
      }, 1000)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/pages/ui-feather.scss";

.table-custom-style {
  font-size: 13px !important;
  white-space: nowrap !important;
  min-height: 140px !important;

  tr,
  th,
  td {
    //vertical-align: middle !important;
    //text-align: center !important;
  }
}

.custom-font {
  font-size: 13px !important;
}

.custom-font-validation {
  font-size: 11px;
}

.cell-with-description div {
  margin: 2px 0px;
}

.task-title {
  display: flex;
  align-items: center;
  margin-left: 50px;
  margin-top: 25px;
  margin-bottom: 10px;
}

.subtask-title {
  display: flex;
  align-items: center;
  margin-left: 50px;
  margin-bottom: 5px;
}

.subtask-title::before {
  content: "";
  display: inline-block;
  width: 8px; /* Adjust the size of the circle point as needed */
  height: 8px; /* Adjust the size of the circle point as needed */
  background-color: #c9c6c6; /* Adjust the color of the circle point as needed */
  border-radius: 50%;
  margin-right: 8px; /* Adjust the space between the circle point and the subtask title as needed */
}

.link {
  margin-left: 5px;
  margin-right: 5px;
}

.second-layer-margin {
  margin-top: 15px;
}

.switch-button-margin {
  margin-top: 6px;
  margin-left: 15px;
}
</style>
